import type { NamespacePackagesTranslation } from "../../i18n-types";

const en = {
  // TODO: insert translations

  connect2tips: "Please switch to the login account in the wallet.",
  createdAt: "Created at",
  expiredAt: "Expired at",
  myvips: "Subscriptions",
  transfer: "Transfer",
  receiver: "Recipient address",
  subtips:
    "Reminder: Membership benefits can be transferred and we suggest subscribing to Blue Card membership.",
  logout: "Logout",
  login: "Sign in",
  loginFirst: "Please sign in first",
  loginForRead: "Sign in to read for free",
  refreshTips:
    "Please refresh this page if you're still unable to see the content after signing in.",
  refreshBtn: "Refresh",
  unlockForRead: "Unlock",
  buyVIPTitle: "🎉Subscribing now!",
  buyVIPTips:
    "Reminder: Membership benefits can be transferred and we suggest subscribing to Blue Card membership.",
  checkoutStatusText: {
    subscribe: "Subscribe",
    queryRate: "Searching for exchange rate...",
    queryRateOk: "Rate: 1{symbol} = {amount}USD",
    queryRateError: "Failed: {error}",
    payingTip: "Please complete the payment in your wallet.",
    success: "Subscription successful. ",
    refreshTip: "Page refreshes to enable subscription.",
    failure: "Failed, please try again...",
  },
  connectwallet: "Connect wallet",
} satisfies NamespacePackagesTranslation;

export default en;
