import type { NamespaceHomepageTranslation } from '../../i18n-types'

const homepage = {
	// TODO: insert translations
    seeAll:"See All",
    createdAt:"Date created",
    lastUpdatedAt:"Last updated",
    contents:"Table of contents",
    paidCanRead:"Subscription required",
    loginCanRead:"Login required",
    allCanRead:"Free",

} satisfies NamespaceHomepageTranslation

export default homepage
