import type { NamespaceHomepageTranslation } from "../../i18n-types";

const zh_homepage = {
  // TODO: insert translations
  seeAll: "发现更多",
  createdAt:"创建日期",
  lastUpdatedAt:"最近更新",
  contents:"目录",
  paidCanRead:"订阅解锁",
  loginCanRead:"登录阅读",
  allCanRead:"自由阅读",

} satisfies NamespaceHomepageTranslation;

export default zh_homepage;
