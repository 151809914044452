import type { NamespacePackagesTranslation } from "../../i18n-types";

const zh_packages = {
  // TODO: insert translations

  connect2tips: "请在钱包中切换到登录账号",
  createdAt: "创建于",
  expiredAt: "过期日",
  myvips: " VIP卡包",
  transfer: "转让",
  receiver: "接收地址",
  subtips: "温馨提示：会员权益可以转让给其他人，推荐订阅蓝卡会员。",
  logout: "退出登录",
  login: "登录",
  loginFirst: "请先登录",
  loginForRead: "登录后可免费阅读",
  refreshTips: "登录后还是没看到内容，请刷新本页面。",
  refreshBtn: "刷新",
  unlockForRead: "解锁阅读全文",
  buyVIPTitle: "🎉欢迎加入VIP!",
  buyVIPTips: "温馨提示：会员权益可以转让给其他人，推荐订阅蓝卡会员。",
  checkoutStatusText: {
    subscribe: "订阅",
    queryRate: "正在为您查询汇率...",
    queryRateOk: '汇率: 1{symbol} = {amount}USD',
    queryRateError: "汇率查询失败：{error}",
    payingTip: "请在你的钱包里完成支付...",
    success: "您已成功订阅！",
    refreshTip: "页面即将刷新，以启用您的订阅",
    failure: "订阅失败，请重试...",
  },
  connectwallet:"连接钱包"
} satisfies NamespacePackagesTranslation;

export default zh_packages;
